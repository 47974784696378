const v2NonAuthLayout = () => import( '../tailwind/layouts/NonAuthLayout' );
const v2AuthLayout = () => import( '../tailwind/layouts/AuthLayout' );
const v2SignupAndLogin = () => import( '../tailwind/pages/SignupAndLogin' );
const v2DashboardPage = () => import( '../tailwind/pages/Dashboard' );
const v2NotificationsPage = () => import( '../tailwind/pages/Notifications' );
const v2WhitelistPage = () => import( '../tailwind/pages/Whitelist' );
const v2CustomDomainPage = () => import( '../tailwind/pages/CustomDomain' );
const v2IntegrationsPage = () => import( '../tailwind/pages/Integrations' );
const v2LibraryPage = () => import( '../tailwind/pages/Library' );
const v2WatermarkPage = () => import( '../tailwind/pages/Watermark' );
const v2MetricsPage = () => import( '../tailwind/pages/Metrics' );
const v2SettingsPage = () => import( '../tailwind/pages/Settings' );
const v2NotFound404 = () => import( '../tailwind/pages/NotFound404' );
const Dam = () => import( '../tailwind/pages/Dam' );
const DamSafariAuth = () => import( '../tailwind/pages/DamSafariAuth' );

let damRoutes = {
	path: '/dam',
	component: Dam,
	meta: {
		title: window.optml_data.labels.page_titles.dam
	},
	name: 'DAM',
};

let damITPAuth = {
	path: '/dam-itp-auth',
	name: 'DAM ITP Auth',
	component: DamSafariAuth
};

let v2Auth = {
	path: '/',
	component: v2NonAuthLayout,
	name: 'Authentication v2',
	children: [
		{
			path: 'login',
			name: 'Login',
			meta: {
				title: window.optml_data.labels.page_titles.login
			},
			component: v2SignupAndLogin
		},
		{
			path: 'register',
			name: 'Signup',
			meta: {
				title: window.optml_data.labels.page_titles.register
			},
			component: v2SignupAndLogin
		},
		{
			path: 'validate',
			name: 'Validate',
			meta: {
				title: window.optml_data.labels.page_titles.validate
			},
			component: v2SignupAndLogin
		},
		{
			path: 'recover',
			name: 'Recover',
			meta: {
				title: window.optml_data.labels.page_titles.recover
			},
			component: v2SignupAndLogin
		},
		{
			path: 'recover_validate',
			name: 'RecoverValidate',
			meta: {
				title: window.optml_data.labels.page_titles.recover_validate
			},
			component: v2SignupAndLogin
		},
		{
			path: 'validate/:token',
			name: 'ValidateToken',
			meta: {
				title: window.optml_data.labels.page_titles.validate_token
			},
			component: v2SignupAndLogin
		},
		{
			path: '/404',
			name: '404',
			meta: {
				title: window.optml_data.labels.page_titles.not_found404
			},
			component: v2NotFound404
		}
	]
};

let v2Dashboard = {
	path: '/',
	component: v2AuthLayout,
	name: 'Dashboard v2',
	children: [
		{
			path: 'dashboard',
			name: 'Dashboard',
			meta: { requires_auth: true, title: window.optml_data.labels.page_titles.dashboard },
			component: v2DashboardPage
		},
		{
			path: 'notifications',
			name: 'Notifications',
			meta: { requires_auth: true, title: window.optml_data.labels.page_titles.notifications },
			component: v2NotificationsPage
		},
		{
			path: 'whitelist',
			name: 'Allowed Domains',
			meta: {
				requires_auth: true,
				title: window.optml_data.labels.page_titles.whitelist
			},
			component: v2WhitelistPage
		},
		{
			path: 'custom_domain',
			name: 'Custom Domain',
			meta: {
				requires_auth: true,
				title: window.optml_data.labels.page_titles.custom_domain,
				contextButton: { label: 'Add new domain', event: 'newCustomDomainButton' }
			},
			component: v2CustomDomainPage
		},
		{
			path: 'integrations',
			name: 'Integrations',
			meta: { requires_auth: true,title: window.optml_data.labels.page_titles.integrations },
			component: v2IntegrationsPage
		},
		{
			path: 'library',
			name: 'Library',
			meta: { requires_auth: true,title: window.optml_data.labels.page_titles.library },
			component: v2LibraryPage
		},
		{
			path: '/watermark/',
			name: 'Watermark',
			meta: { requires_auth: true, section: 'Images',title: window.optml_data.labels.page_titles.watermark },
			component: v2WatermarkPage,
			children: [
				{
					path: 'images',
					name: 'Images',
					meta: { requires_auth: true, section: 'Images',title: window.optml_data.labels.page_titles.watermark_images },
					component: v2WatermarkPage,
				},
				{
					path: 'rules',
					name: 'Rules',
					meta: { requires_auth: true, section: 'Rules',title: window.optml_data.labels.page_titles.watermark_rules },
					component: v2WatermarkPage,
				}
			]
		},
		{
			path: '/metrics/',
			name: 'Metrics',
			meta: { requires_auth: true, section: 'Visits',title: window.optml_data.labels.page_titles.metrics },
			component: v2MetricsPage,
			children: [
				{
					path: 'visits',
					name: 'Visits',
					meta: { requires_auth: true, section: 'Visits',title: window.optml_data.labels.page_titles.metrics_visits },
					component: v2MetricsPage
				},
			],
		},
		{
			path: '/settings/',
			name: 'Settings',
			meta: { requires_auth: true, section: 'Billing',title: window.optml_data.labels.page_titles.settings },
			component: v2SettingsPage,
			children: [
				{
					path: 'account',
					name: 'Account',
					meta: { requires_auth: true, section: 'Account',title: window.optml_data.labels.page_titles.account },
					component: v2SettingsPage
				},
				{
					path: 'api-keys',
					name: 'APIKeys',
					meta: { requires_auth: true, section: 'APIKeys',title: window.optml_data.labels.page_titles.api_keys },
					component: v2SettingsPage
				},
				{
					path: 'password',
					name: 'Password',
					meta: { requires_auth: true, section: 'Password',title: window.optml_data.labels.page_titles.password },
					component: v2SettingsPage
				},
				{
					path: 'email',
					name: 'Email',
					meta: { requires_auth: true, section: 'Email',title: window.optml_data.labels.page_titles.email },
					component: v2SettingsPage
				},
				{
					path: 'billing',
					name: 'Billing',
					meta: { requires_auth: true, section: 'Billing',title: window.optml_data.labels.page_titles.billing },
					component: v2SettingsPage
				},
				{
					path: 'billing_wait',
					name: 'Wait',
					meta: { requires_auth: true, section: 'Wait',title: window.optml_data.labels.page_titles.wait },
					component: v2SettingsPage
				},
			]
		},
	],
};

export default [
	{
		path: '/',
		component: v2AuthLayout,
		redirect: '/dashboard',
	},
	v2Auth,
	v2Dashboard,
	damRoutes,
	damITPAuth,
	{ path: '/hs/info*', redirect: '/index.php?page=helpscout&hs_request=info' },
	{ path: '/index.php?page=helpscout&hs_request=info' },
	{ path: '/index.php?page=switch_user*' },
	{
		path: '/hs/info:catchAll(.*)',
		redirect: '/index.php?page=helpscout&hs_request=info'
	},
	{
		path: '/index.php?page=helpscout&hs_request=info'
	},
	{
		path: '/index.php?page=switch_user:catchAll(.*)'
	},
	{
		path: '/:catchAll(.*)',
		redirect: '/404'
	}
];
